import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import contact from '../../../src/Assets/contact.png';

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', message: '' });
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState('');

  const validateForm = () => {
    let formErrors = {};
    if (!formData.name.trim()) formErrors.name = 'Name is required';
    if (!formData.email.trim()) {
      formErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = 'Email is invalid';
    }
    if (!formData.phone.trim()) {
      formErrors.phone = 'Phone is required';
    } else if (!/^\d{10}$/.test(formData.phone)) {
      formErrors.phone = 'Phone number must be 10 digits';
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    emailjs.send('service_5kun32t', 'template_xzx7yc3', formData, 'SPcR4yAPJfG3v5g5w')
      .then(() => {
        setSuccess('Message sent successfully!');
        setTimeout(() => {
          setSuccess('')
        }, 3000);
        setFormData({ name: '', email: '', phone: '', message: '' }); // Reset form data
        setErrors({});
      })
      .catch(() => {
        setSuccess('Failed to send message. Please try again later.');
      });
  };

  return (
    <Container className="mt-5 p-4" style={{ borderRadius: '10px', height: '90vh', display: 'grid', placeItems: 'center' }}>
      <Row style={{ width: "100%" }}>
        <Col md={6} className="d-flex align-items-center justify-content-center">
          <img src={contact} alt="Dummy" style={{ maxWidth: '100%', borderRadius: '10px', maxHeight: "500px" }} />
        </Col>
        <Col md={6} style={{ height: "100%", alignSelf: 'center' }}>
          <Form onSubmit={handleSubmit} className="p-4" style={{ borderRadius: '10px', color: '#ffffff' }}>
            {success && <Alert variant={success.includes('failed') ? 'danger' : 'success'}>{success}</Alert>}
            <Form.Group controlId="formName" className="mb-3">
              <Form.Control
                name="name"
                type="text"
                className='custom-placeholder'
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleInputChange}
                style={{ backgroundColor: 'transparent', color: '#fff', borderColor: '#5a5a5a' }}
              />
              {errors.name && <Form.Text className="text-danger" style={{textAlign:'left', width:'100%'}}>{errors.name}</Form.Text>}
            </Form.Group>

            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Control
                name="email"
                type="email"
                placeholder="Enter your email"
                  className='custom-placeholder'
                value={formData.email}
                onChange={handleInputChange}
                style={{ backgroundColor: 'transparent', color: '#fff', borderColor: '#5a5a5a' }}
              />
              {errors.email && <Form.Text className="text-danger" style={{textAlign:'left', width:'100%'}}>{errors.email}</Form.Text>}
            </Form.Group>

            <Form.Group controlId="formPhone" className="mb-3">
              <Form.Control
                name="phone"
                type="text"
                placeholder="Enter your phone number"
                  className='custom-placeholder'
                value={formData.phone}
                onChange={handleInputChange}
                style={{ backgroundColor: 'transparent', color: '#fff', borderColor: '#5a5a5a' }}
              />
              {errors.phone && <Form.Text className="text-danger" style={{textAlign:'left', width:'100%'}}>{errors.phone}</Form.Text>}
            </Form.Group>

            <Form.Group controlId="formMessage" className="mb-3">
              <Form.Control
                name="message"
                as="textarea"
                rows={4}
                placeholder="Enter your message"
                value={formData.message}
                  className='custom-placeholder'
                onChange={handleInputChange}
                style={{ backgroundColor: 'transparent', color: '#fff', borderColor: '#5a5a5a' }}
              />
            </Form.Group>

            <Button variant="primary" type="submit" style={{ backgroundColor: '#5a5a5a', borderColor: '#5a5a5a' }}>
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import mkc from "../../Assets/Projects/mkc2.png";
import custom from "../../Assets/Projects/custom.png";
import vhai from "../../Assets/Projects/vhai3.png";
import cgherbal from "../../Assets/Projects/cgherbal2.png";
import veda from "../../Assets/Projects/veda2.png";
import dmt from "../../Assets/Projects/dmt2.png";
import autobazar from "../../Assets/Projects/autobazzar.png";
import hostel from "../../Assets/Projects/hostel.png";
import luxurati from "../../Assets/Projects/luxurati.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mkc}
              isBlog={false}
              title="Major Kalshi Classes"
              description="Major Kalshi Classes is a comprehensive e-learning platform offering live classes, test series, and offline coaching for defense exam aspirants, along with hostel and medical services. The platform provides expert guidance and personalized coaching to help students excel in their defense career ambitions."
              // ghLink="https://github.com/soumyajit4419/Plant_AI"
              demoLink="https://majorkalshiclasses.com/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={custom}
              isBlog={false}
              title="The Custom Bakers"
              description="Custom Bakers is an online cake delivery app specializing in personalized cakes and photo cakes for every occasion. Customers can customize designs, flavors, and sizes, and have their cakes, including photo-printed ones, delivered right to their doorstep."
              // ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              demoLink="https://www.thecustombakers.com/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={dmt}
              isBlog={false}
              title="Deliver My Tune"
              description="Deliver My Tune is a music distribution website that helps artists deliver their songs and albums to over 100+ platforms worldwide, including Spotify and Amazon Music. In addition to distribution, it provides customized artist portfolios, enabling musicians to showcase their work and expand their audience effectively."
              // ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              demoLink="https://www.delivermytune.com/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={veda}
              isBlog={false}
              title="Veda"
              description="Veda is an e-learning platform offering live classes, recorded lectures, and test series, providing a comprehensive educational experience with interactive and on-demand learning options. It also features personalized support to enhance the learning journey."
              // ghLink="https://github.com/soumyajit4419/Chatify"
              demoLink="https://www.vedaacademy.org.in/"
            />
          </Col>

        

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={cgherbal}
              isBlog={false}
              title="Chhattishgarh Herbal"
              description="Chhattisgarh Herbals is an e-commerce brand offering premium herbal products, empowering tribal women. Supported by the Chhattisgarh Minor Forest Produce Cooperative Federation, it uplifts over 1.2 million forest dwellers. The brand promotes eco-friendly products, preserving traditional knowledge and fostering community growth."
              // ghLink="https://github.com/soumyajit4419/Editor.io"
              demoLink="https://chhattisgarhherbal.com/"              
            />
          </Col>

         <Col md={4} className="project-card">
            <ProjectCard
              imgPath={vhai}
              isBlog={false}
              title="V4 Health"
              description="V4 Health is a healthcare app where users can upload medical reports, consult doctors via video call, and receive personalized diet plans recommended by professionals. It provides convenient, accessible healthcare solutions from the comfort of your home."
              demoLink="https://www.v4health.co/"     
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={autobazar}
              isBlog={false}
              title="Autobazar"
              description="AutoBazzar is an online auction site dedicated to buying and selling vehicles. Users can bid on a wide range of cars, trucks, and other vehicles, making it a convenient platform for both buyers and sellers to find great deals and competitive prices."
              // ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              demoLink="https://www.autobazaarauction.com/"     
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={hostel}
              isBlog={false}
              title="Hostel Duniya"
              description="Hostel Duniya is a website offering PG booking, hotel reservations, and hostel rental services. It provides an easy-to-use platform for users to find affordable accommodation options, whether they are looking for long-term stays in hostels or short-term hotel bookings. With a wide range of choices, Hostel Duniya ensures comfort and convenience for travelers and tenants alike."
              // ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              demoLink="https://hostelduniya.in/"      
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={luxurati}
              isBlog={false}
              title="Luxurati"
              description="Luxurati is an e-commerce platform specializing in luxury cars and bikes, offering both brand-new and second-hand vehicles. With a focus on premium brands, Luxurati provides an exclusive marketplace for buyers looking to invest in high-end automobiles and motorcycles, ensuring quality and performance in every purchase."
              demoLink="https://master.d1tv18xr62ytcz.amplifyapp.com/"    
            />
          </Col>  
        </Row>
      </Container>  
    </Container>
  );
}

export default Projects;
